import request from '@/utils/request';

export async function getSongsList(params) {
  params.is_filterBang =
    params.is_filterBang === undefined ? 1 : params.is_filterBang;
  params.isFilterArchived =
    params.isFilterArchived === undefined ? 1 : params.isFilterArchived;
  if (
    params.sortByDesc === undefined &&
    params.demand_rate_order === undefined
  ) {
    params.sortByDesc = 'rank_score';
  }
  params.include =
    'intonation,mpg.torrent,mp3,ts,fourk.torrent,h265.torrent,mp3IntonationFileRecord.ercsFile,mp3IntonationFileRecord.stereoFile,mp3IntonationFileRecord.originalFile,mp3IntonationFileRecord.accompanyFile,lastMelody.file,lastAccompany.file,lastEncryptionFeature.file,intonation.originalFeatureFile,intonation.stereoFile,intonation.originalFile,banSong,mediaMp3.intonation.stereoFile,mediaMp3.intonation.ercsFile';
  return request('/music-library/songs', {
    params,
  });
}

export async function getStatisticsData(params) {
  params.is_filterBang =
    params.is_filterBang === undefined ? 1 : params.is_filterBang;
  params.isFilterArchived =
    params.isFilterArchived === undefined ? 1 : params.isFilterArchived;
  return request('/music-library/songs/statistics', {
    params,
  });
}

export async function getSingerList(params) {
  params.is_filterBang =
    params.is_filterBang === undefined ? 1 : params.is_filterBang;
  return request('/music-library/singers', {
    params: {
      ...params,
      include:
        'extraLagerAvatarFile,lagerAvatarFile,extraMiddleAvatarFile,middleAvatarFile,smallAvatarFile,singerNameMultipleLanguage',
    },
  });
}

export async function getSingerCount(params) {
  params.is_filterBang =
    params.is_filterBang === undefined ? 1 : params.is_filterBang;
  return request('/music-library/singers/statistics', {
    params,
  });
}

export async function updateSingerAlterNames(params) {
  return request(`/music-library/api/singers/alterNames/${params.singer_id}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 保存歌曲音准文件
export async function saveIntonationFile(params) {
  return request(`/music-library/song-intonation/${params.type}`, {
    method: 'POST',
    data: params,
  });
}

// 获取歌曲文件信息
export async function getSongFileList(params) {
  params.include = 'versionlog,torrent';
  return request(`/music-library/song-files/${params.qk_type}`, {
    params,
  });
}

// 计算MD5
export async function saveRecalculationMD5(params) {
  return request('/music-library/song-files/compute', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 上传歌星头像
export async function saveSingerImgUpload(params) {
  return request('/music-library/singers/avatar', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 导出歌曲文件信息
export async function saveSongFileExport(params) {
  return request('/music-library/song-files/export', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function uploadSingerFile(params) {
  return request.post(
    `/music-library/singers/upload-singer-file`,
    params.formData,
    {
      headers: {
        'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`,
      },
    }
  );
}

export async function uploadSingerFileDetail(params) {
  return request(`/music-library/singers/infos`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 导入歌曲文件
export async function uploadSongFile(params) {
  return request.post(
    `/music-library/song-files/upload-song-file`,
    params.formData,
    {
      headers: {
        'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`,
      },
    }
  );
}

// 执行更新具体歌曲文件
export async function uploadSongFileDetail(params) {
  return request(
    `/music-library/song-files/${params.type.toLowerCase()}/batchSave`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    }
  );
}

// 导出歌曲文件信息
export async function songExport(params) {
  params.is_filterBang =
    params.is_filterBang === undefined ? 1 : params.is_filterBang;
  params.isFilterArchived =
    params.isFilterArchived === undefined ? 1 : params.isFilterArchived;
  return request('/music-library/songs/export', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 获取oss配置信息
export async function getOSSConfig(params) {
  return request('/music-library/oss/config', {
    params,
  });
}

// 批量上传歌星头像
export async function saveSingersAvatarUpload(params) {
  return request('/music-library/singers/avatar', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 导出歌星信息
export async function singerExport(params) {
  return request('/music-library/singers/export', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 获取导入列表
export async function getImportTasks(params) {
  return request(`/music-library/import-tasks`, {
    params,
  });
}

// 获取导出列表
export async function getExportTasks(params) {
  return request(`/music-library/export-tasks`, {
    params,
  });
}

/**
 * 获取分析数据
 */
export async function getSongAnalyze() {
  return request(`/music-library/song-analyze`);
}

// 导入4K歌曲映射文件
export async function uploadFourkSongFileMap(params) {
  return request.post(
    `/music-library/song-files/upload-fourk-song-file-map`,
    params.formData,
    {
      headers: {
        'Content-Type': `multipart/form-data;boundary=${new Date().getTime()}`,
      },
    }
  );
}

// 执行更新4K具体歌曲映射文件
export async function uploadFourkSongFileMapDetail(params) {
  return request(`/music-library/song-files/batchSaveFourkSongFileMap`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
